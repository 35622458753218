import React from "react"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"

const SEO = ({
  title,
  ogTitle,
  description,
  ogDescription,
  image,
  article,
  noindex = false,
  nofollow = false,
  children,
}) => {
  const { pathname } = useLocation()

  const seo = {
    titleTemplate: `%s | A creative agency`,
    title: title,
    ogTitle: ogTitle,
    description:
      description,
    ogDescription:
      ogDescription,
    image:
      image === "default"
        ? "https://res.cloudinary.com/increas-io/image/upload/v1650645314/Crypto-Tools_m2voqz.png"
        : image,
    url: `https://crypto.increas.io/${pathname}`,
  }
  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
      title={seo.title}
      titleTemplate={seo.titleTemplate}
    >
      <meta
        name="robots"
        content={`${noindex ? "noindex" : "index"}, ${
          nofollow ? "nofollow" : "follow"
        }`}
      />

      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      {seo.url && <meta property="og:url" content={seo.url} />}
      {(article ? true : null) && <meta property="og:type" content="article" />}
      {seo.title && <meta property="og:title" content={seo.ogTitle} />}
      {seo.description && (
        <meta property="og:description" content={seo.ogDescription} />
      )}
      {seo.image && <meta property="og:image" content={seo.image} />}
      <meta name="twitter:card" content="summary_large_image" />
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Raleway:wght@100;300;400;500&family=Syncopate:wght@400;700&display=swap"
        rel="preload"
        as="style"
        onLoad="this.onload=null;this.rel='stylesheet'"
      />

      {children}
    </Helmet>
  )
}
export default SEO
