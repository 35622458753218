import * as React from "react"
import Form from "../components/Form"
import Hero from "../components/Hero"
import Ladder from "../components/Ladder"
import Layout from "../components/layout/Layout"
import SEO from "../components/seo"

const IndexPage = () => {
  return (
    <>
      <SEO 
      title="Crypto Tools Development Agency, NFT Platforms, Dashboards"
      description="Crypto Websites Development on React, Svelte, Next.js. Secure, Fast, Reliable Crypto Dashboards, Crypto Tools, Websites development. Let's Discuss Your Project"
      />
      <Layout>
        <Hero />
        <Ladder
          title="Crypto Tools"
          description="We will help you create dashboards, analytics dashboards, live data
        pages, trading volume, and websites for the community and users. <br/><br/> These
        tools will help you to communicate more with your community and gain
        more trust."
          img="https://res.cloudinary.com/increas-io/image/upload/v1650645314/Crypto-Tools_m2voqz.png"
        />
        <Ladder
          revert
          title="NFT platforms"
          description="Do you have your NFT collections and plan to sell them? <br/> We will build
        your website with your unique style. Our team will create all popular
        wallets integrations and map NFT actions to your smart contract."
          img="https://res.cloudinary.com/increas-io/image/upload/v1650645314/NFT-platforms_osvxql.png"
        />
        <Ladder
          title="Crypto promotional websites"
          description="Focus more on your product, we will take care of your website. <br/>
        Increasio uses React-based frameworks to develop the best web
        experiences for your community and users."
          img="https://res.cloudinary.com/increas-io/image/upload/v1650645314/Crypto-promotional-websites_lunrsv.png"
        />
        <Ladder
          revert
          title="Security"
          description="Or developments are 100% secure. <br/> Our approach eliminates vulnerable
        communication between frontend and backend, making your data secure. <br/>
        We are using headless approach to create integrations and CMS
        development."
          img="https://res.cloudinary.com/increas-io/image/upload/v1650645314/Security_flqtxj.png"
        />
        <Ladder
          title="API integrations"
          description="At Increasio we are big fans of automatization! We will pull all
        possible data from APIs and automate all possible workflows. <br/> We can
        integrate required applications and software into your products
        seamlessly."
          img="https://res.cloudinary.com/increas-io/image/upload/v1650645314/API-integrations_tmldde.png"
        />
        <Form />
      </Layout>
    </>
  )
}

export default IndexPage
