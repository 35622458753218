import React from "react"
import { motion } from "framer-motion"

const Hero = () => {
  return (
    <section className="relative h-[90vh] mb-[10vh]">
      <video
        autoPlay
        muted
        loop
        className="absolute inset-0 z-0 object-cover w-full h-full"
      >
        <source
          src="https://res.cloudinary.com/increas-io/video/upload/v1652698601/background_p2uq7p.mp4"
          type="video/mp4"
        />
      </video>

      <div className="container relative z-10 flex items-center h-full py-10 md:py-20">
        <h1 className="text-2xl font-bold font-heading xl:text-7xl">
          NFT & <span className="text-yellow-400">Blockchain</span> <br />
          <span className="text-fuchsia-500">Front-End </span> <br />{" "}
          development{" "}
          <motion.span
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              duration: 0.7,
              repeat: Infinity,
              repeatType: "reverse",
            }}
          >
            |
          </motion.span>
        </h1>
      </div>
      <div className="absolute bottom-0 left-0 w-full bg-fuchsia-500">
        <div className="container py-2 text-sm font-semibold text-black font-heading">
          Making the world a better place through constructing elegant websites
        </div>
      </div>
    </section>
  )
}

export default Hero
