import React from "react"
import { motion } from "framer-motion"

const removeFirstWord = str => {
  const index = str.indexOf(" ")
  if (index === -1) {
    return ""
  }

  return str.substring(index + 1)
}

const Ladder = ({ title, description, img, revert = false }) => {
  return (
    <section
      className={`container flex items-center py-20 xl:space-x-20 md:py-40 ${
        revert
          ? "xl:flex-row-reverse xl:space-x-reverse flex-col-reverse"
          : "xl:flex-row flex-col-reverse"
      } `}
    >
      <div className="xl:w-1/2">
        <motion.h2
          initial={{
            y: -10,
            scale: 1.3,
          }}
          whileInView={{ y: 0, scale: 1 }}
          viewport={{ margin: "-100px", once: true }}
          transition={{ type: "just", delay: 0.1 }}
          className="text-2xl font-bold xl:text-5xl font-heading"
        >
          <span
            className={`${revert ? "text-yellow-500" : "text-fuchsia-500"}`}
          >
            {title.split(" ")[0]}
          </span>{" "}
          {removeFirstWord(title)}
        </motion.h2>
        <motion.p
          initial={{
            y: 50,
            scale: 1.1,
            opacity: 0.5,
          }}
          whileInView={{ y: 0, scale: 1, opacity: 1 }}
          viewport={{ margin: "-100px", once: true }}
          transition={{ type: "just", delay: 0.1 }}
          className="prose xl:mt-5 xl:text-xl text-white/80"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
      <div className="xl:w-1/2"><img alt="crypto development services Increasio" src={img}/></div>
    </section>
  )
}

export default Ladder
