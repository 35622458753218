import React from "react"
import { FaFacebook, FaInstagram, FaTwitter, FaGithub } from "react-icons/fa"
import { Link } from "gatsby"

const navigation = {
  solutions: [
    { name: "Metawrite", href: "https://increas.io/metawrite" },
    { name: "Gatsby Developers", href: "https://increas.io/gatsby" },
    { name: "Next Developers", href: "https://increas.io/next" },
    { name: "Headless Shopify", href: "https://increas.io/shopify" },
    { name: "Svelte Developers", href: "https://increas.io/svelte" },
    { name: "Headless Wordpress", href: "https://increas.io/wordpress" },
  ],
  support: [
    { name: "Contact", href: "https://increas.io/contact" },
    { name: "Community", href: "https://discord.gg/n3WZxtbkCm" },
  ],
  company: [
    { name: "About", href: "https://increas.io/about" },
    { name: "Jamstack development", href: "https://increas.io/services" },
    // { name: 'Blog', href: '/blog' },
    // { name: 'Jobs', href: '/careers' },
    { name: "Partners", href: "https://increas.io/partners" },
  ],
  legal: [
    { name: "Terms", href: "https://increas.io/terms" },
    { name: "Privacy", href: "https://increas.io/privacy" },
  ],
  social: [
    {
      name: "Facebook",
      href: "https://facebook.com/increasio",
      icon: FaFacebook,
    },
    {
      name: "Instagram",
      href: "https://instagram.com/increasio",
      icon: FaInstagram,
    },
    {
      name: "Twitter",
      href: "https://twitter.com/increasio",
      icon: FaTwitter,
    },
    {
      name: "Github",
      href: "https://github.com/increas-io",
      icon: FaGithub,
    },
  ],
}

export default function Footer() {
  return (
    <footer
      className="bg-white/5 text-white/70"
      aria-labelledby="footer-heading"
    >
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8 xl:col-span-1">
            <img
              className="h-10"
              src="https://i.imgur.com/1JGEvd9.png"
              alt="Increasio Logo"
            />
            <p>
              Making the world a better place through constructing elegant
              websites.
            </p>
            <div className="flex space-x-6">
              {navigation.social.map(item => (
                <a
                  key={item.name}
                  href={item.href}
                  className="hover:text-white transition-colors"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div>
          </div>
          <div className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold tracking-wider uppercase">
                  Solutions
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.solutions.map(item => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-white/40 hover:text-white/80 transition-colors"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold  tracking-wider uppercase">
                  Support
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.support.map(item => (
                    <li key={item.name}>
                      <a
                        className="text-white/40 hover:text-white/80 transition-colors"
                        href={item.href}
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold  tracking-wider uppercase">
                  Company
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.company.map(item => (
                    <li key={item.name}>
                      <a
                        className="text-white/40 hover:text-white/80 transition-colors"
                        href={item.href}
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold tracking-wider uppercase">
                  Legal
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.legal.map(item => (
                    <li key={item.name}>
                      <a
                        className="text-white/40 hover:text-white/80 transition-colors"
                        href={item.href}
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-12">
          <p className="text-base text-white/30 xl:text-center">
            Copyright &copy; {new Date().getFullYear()} Increasio. All rights
            reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}
