import React, { useRef, useState } from "react"
import { Link } from "gatsby"
import axios from "axios"

export default function Form() {
  const [agreed, setAgreed] = useState(false)

  const formSparkUrl = `https://submit-form.com/BzsglEwg`
  //   const recaptchaKey = "6LdRHRkcAAAAAA_w3WYpiHD2Pt11mQJa2W4Q8Gms"
  //   const recaptchaRef = useRef()

  const initialFormState = {
    firstname: "",
    lastname: "",
    company: "",
    email: "",
    message: "",
  }

  const [formState, setFormState] = useState(initialFormState)
  const [submitting, setSubmitting] = useState(false)
  const [message, setMessage] = useState()
  //   const [recaptchaToken, setReCaptchaToken] = useState()

  const submitForm = async event => {
    event.preventDefault()
    setSubmitting(true)
    await postSubmission()
    setSubmitting(false)
  }

  const postSubmission = async () => {
    const payload = {
      ...formState,
      //   "g-recaptcha-response": recaptchaToken,
    }

    try {
      const result = await axios.post(formSparkUrl, payload)
      console.log(result)
      setMessage({
        class: "bg-green-50",
        iconcolor: "text-green-400",
        textcolor: "text-green-800",
        text: "Thanks, someone will be in touch shortly.",
      })
      setFormState(initialFormState)
      //   recaptchaRef.current.reset()
    } catch (error) {
      console.log(error)
      setMessage({
        class: "bg-red-500/10",
        iconcolor: "text-red-400",
        textcolor: "text-red-500",
        text: "Sorry, there was a problem. Please try again or open support ticket in our Discord.",
      })
    }
  }

  const updateFormControl = event => {
    const { id, value } = event.target
    const formKey = id
    const updatedFormState = { ...formState }
    updatedFormState[formKey] = value
    setFormState(updatedFormState)
  }

  //   const updateRecaptchaToken = token => {
  //     setReCaptchaToken(token)
  //   }

  return (
    <div
      className="px-4 py-16 overflow-hidden sm:px-6 lg:px-8 lg:py-24"
      id="contact"
    >
      <div className="relative max-w-xl mx-auto">
        <svg
          className="absolute transform translate-x-1/2 left-full"
          width={404}
          height={404}
          fill="none"
          viewBox="0 0 404 404"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="85737c0e-0916-41d7-917f-596dc7edfa27"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-800"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={404}
            fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
          />
        </svg>
        <svg
          className="absolute bottom-0 transform -translate-x-1/2 right-full"
          width={404}
          height={404}
          fill="none"
          viewBox="0 0 404 404"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="85737c0e-0916-41d7-917f-596dc7edfa27"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={404}
            fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
          />
        </svg>

        <div className="text-center">
          <h2 className="text-3xl font-bold text-white font-heading sm:text-4xl">
            Contact
          </h2>

          {message && (
            <div className={`rounded-md p-4 mt-8 ${message.class}`}>
              <div className="flex">
                <div className="ml-3">
                  <p className={`text-sm font-medium ${message.textcolor}`}>
                    {message.text}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="mt-12">
          <form
            action="https://submit-form.com/BzsglEwg"
            method="POST"
            id="contactForm"
            onSubmit={submitForm}
            className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
          >
            <div>
              <label
                htmlFor="firstname"
                className="block text-sm font-medium text-gray-400"
              >
                First name
              </label>
              <div className="mt-1">
                <input
                  value={formState?.firstname}
                  onChange={updateFormControl}
                  type="text"
                  name="firstname"
                  id="firstname"
                  autoComplete="given-name"
                  className="customInput"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="lastname"
                className="block text-sm font-medium text-gray-400"
              >
                Last name
              </label>
              <div className="mt-1">
                <input
                  value={formState?.lastname}
                  onChange={updateFormControl}
                  type="text"
                  name="lastname"
                  id="lastname"
                  autoComplete="family-name"
                  className="customInput"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="company"
                className="block text-sm font-medium text-gray-400"
              >
                Company
              </label>
              <div className="mt-1">
                <input
                  value={formState?.company}
                  onChange={updateFormControl}
                  type="text"
                  name="company"
                  id="company"
                  autoComplete="organization"
                  className="customInput"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-400"
              >
                Email
              </label>
              <div className="mt-1">
                <input
                  value={formState?.email}
                  onChange={updateFormControl}
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  className="customInput"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="message"
                className="block text-sm font-medium text-gray-400"
              >
                Message
              </label>
              <div className="mt-1">
                <textarea
                  value={formState?.message}
                  onChange={updateFormControl}
                  id="message"
                  name="message"
                  rows={4}
                  className="customInput"
                />
              </div>
            </div>
            <div>
              {/* <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={recaptchaKey}
                  onChange={updateRecaptchaToken}
                /> */}
            </div>
            <div className="sm:col-span-2">
              <button
                disabled={submitting}
                type="submit"
                className="w-full px-10 py-4 font-bold uppercase transition-colors duration-300 bg-transparent border font-heading rounded-xl border-fuchsia-500 text-fuchsia-500 hover:text-black hover:bg-fuchsia-500"
              >
                {submitting ? "Submitting..." : "Submit"}
              </button>
              <p className="mt-4 text-base text-gray-500">
                By clicking Submit button, you agree to the{" "}
                <a
                  href="https://increas.io/privacy"
                  target="_blank"
                  rel="noreferrer"
                  className="font-medium text-gray-700 underline"
                >
                  Privacy Policy
                </a>{" "}
                and{" "}
                <a
                  href="https://increas.io/terms"
                  target="_blank"
                  rel="noreferrer"
                  className="font-medium text-gray-700 underline"
                >
                  Terms of Service
                </a>
                .
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
