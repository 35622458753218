import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const Header = () => {
  return (
    <div className="absolute top-0 left-0 right-0">
      <header className="container relative z-10 py-5 h-[80px]">
        <StaticImage
          src="../../../src/images/logo-white.png"
          width={150}
          alt="logo"
        />
      </header>
    </div>
  )
}

export default Header
